<template>
  <div>
    <v-dialog v-model="dialog" max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed small rounded outlined color="grey lighten-1" v-bind="attrs" v-on="on">
          <v-icon>add</v-icon> Filtro
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Añadir un filtro </v-card-title>
        <v-card-text>
          <v-row class="flex-column">
            <v-col>
              <v-select
                v-model="filterSelected"
                :items="availableFilters"
                label="Campo para filtrar"
                hide-details
              />
            </v-col>

            <v-col>
              <component
                @submitForm="addCriteria($event)"
                :is="formComponent"
                :filterParams="filterParams"
              >
                <template v-slot:actions>
                  <v-btn color="error" text @click="closeDialog" class="mr-2"> Cancelar </v-btn>
                  <v-btn color="primary" type="submit"> Aplicar </v-btn>
                </template>
              </component>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
    StringForm: () => import("@/components/crud-md/filter-forms/StringForm"),
    NumberForm: () => import("@/components/crud-md/filter-forms/NumberForm"),
    DateForm: () => import("@/components/crud-md/filter-forms/DateForm"),
    SelectForm: () => import("@/components/crud-md/filter-forms/SelectForm"),
  },
  data: () => ({
    dialog: false,
    filterSelected: null,
    filterForms: [
      { type: "Number", componentName: "NumberForm" },
      { type: "String", componentName: "StringForm" },
      { type: "Date", componentName: "DateForm" },
      { type: "Select", componentName: "SelectForm" },
    ],
  }),
  props: {
    filterableHeaders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    availableFilters() {
      return this.filterableHeaders.map(f => f.text);
    },
    formComponent() {
      if (!this.filterForms || !this.filterSelected) return null;

      return this.filterForms.find(
        f => f.type.toLowerCase() === this.filterSelectedObj.filterable.type.toLowerCase()
      ).componentName;
    },
    filterSelectedObj() {
      return this.filterableHeaders.find(f => f.text === this.filterSelected) || {};
    },
    filterParams() {
      if (!this.filterSelectedObj) return null;
      return this.filterSelectedObj.filterable || null;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.filterSelected = null;
    },
    addCriteria(criteria) {
      criteria.field = this.filterSelectedObj.filterable.field;
      this.$emit("addCriteria", criteria);
      this.closeDialog();
    },
  },
};
</script>

<style scoped></style>
